import React, { useState } from "react";
import Modal from "./Modal";
import { useEffect } from "react";
import "../styles/ContentSwitcher.css";
import universal from "../img/universal.png";
import hvoy from "../img/hvoy.png";
import palm from "../img/palm.png";
import rose from "../img/rose.png";
import orc from "../img/orc.png";
import { ReactComponent as Hundred } from "../img/100.svg";
import { ReactComponent as TwoHundred } from "../img/250.svg";
import { ReactComponent as EightHundred } from "../img/800.svg";
import { ReactComponent as Liter } from "../img/1500.svg";
import { ReactComponent as MadeInRussia } from "../img/madeInRussia.svg";
import { ReactComponent as Separator } from "../img/separator.svg";
import { ReactComponent as WB } from "../img/WB.svg";
import { ReactComponent as Ozon } from "../img/Ozon.svg";
import { ReactComponent as VseInstr } from "../img/VseInstr.svg";
import { ReactComponent as Cancel } from "../img/Cancel.svg";
import { ReactComponent as BuyButton } from "../img/buyButton¸.svg";

function ContentSwitcher() {
  const [activeContent, setActiveContent] = useState("catalog");
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Состояние для отслеживания переворота каждой кнопки
  const [flippedState, setFlippedState] = useState({});

  // Обработчик клика на кнопку
  const handleButtonClick = (id) => {
    setFlippedState((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Переключаем состояние только для выбранной кнопки
    }));
  };
  // Открытие модального окна
  const openModal = () => setIsModalOpen(true);

  // Закрытие модального окна
  const closeModal = () => setIsModalOpen(false);

  // Закрытие модального окна при нажатии Escape
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        closeModal();
      }
    };

    if (isModalOpen) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isModalOpen]);

  const renderContent = () => {
    switch (activeContent) {
      case "catalog":
        return (
          <div className="main">
            <div className="catalog__main-description">
              <p className="catalog__main-description-text-top">
                TOP GREEN — Российский бренд, производящий высококачественные
                удобрения для различных видов растений (таких как хвойные,
                пальмы, фикусы, драцены), а также удобрения для универсального
                применения.
              </p>
              <p className="catalog__main-description-text-bottom">
                Наши удобрения способствуют росту и развитию растений, защищают
                от вредителей, обеспечивают быстрые сроки восстановления после
                стресса. Продукция TOP GREEN исключает риск передозировки, а
                также является полностью безопасной для использования.
              </p>
            </div>
            <div className="content">
              <div className="catalog">
                <h2 className="catalog__title">
                  Средство защиты <br /> для хвойных
                </h2>
                <p className="catalog__description">
                  для всех видов хвойных растений
                  <br />
                  эффективно и усиленный имутитет
                </p>
                <ul className="catalog__list">
                  <li className="catalog__list-item">
                    насыщенный зелёный цвет круглый год
                  </li>
                  <li className="catalog__list-item">
                    на 99% снижает риск гибели
                  </li>
                  <li className="catalog__list-item">
                    быстрое восстановление после ожогов
                  </li>
                  <li className="catalog__list-item">
                    развитие корневой системы
                  </li>
                </ul>
                <div className="catalog__container">
                  <div className="catalog__container-item">
                    <table className="border-none">
                      <thead>
                        <tr>
                          <th className="catalog__container-item-title">
                            Питающие вещества
                          </th>
                          <th className="catalog__container-item-title">%</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Азот</td>
                          <td>2</td>
                        </tr>
                        <tr>
                          <td>Фосфор</td>
                          <td>1</td>
                        </tr>
                        <tr>
                          <td>Калий</td>
                          <td>6</td>
                        </tr>
                        <tr>
                          <td>Магний</td>
                          <td>0.5</td>
                        </tr>
                        <tr>
                          <td>
                            Деионизированная
                            <br />
                            вода
                          </td>
                          <td>62</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="catalog__container-item">
                    <h3 className="catalog__container-item-title">
                      Доп. информация:
                    </h3>
                    <p className="catalog__container-item-text">
                      N-NO3- 8.54 г/л
                    </p>
                    <p className="catalog__container-item-text">
                      N-NH4 - 2.98 г/л
                    </p>
                    <p className="catalog__container-item-text">
                      N-NH2 - 11.52 г/л
                    </p>
                  </div>
                </div>
                <div className="catalog__buy_container">
                  <div className="catalog__buy_container_left">
                    <p className="catalog__buy_container_left-shop">
                      <strong>WB / Ozon / Все Инстр.</strong>
                    </p>

                    {/* Блок для кнопки с id="1" */}
                    <div
                      className={`catalog__flip_card ${
                        flippedState["1"] ? "flipped" : ""
                      }`}
                    >
                      {/* Передняя сторона кнопки */}
                      <div
                        onClick={() => handleButtonClick("1")}
                        className="catalog__flip_card_front"
                      >
                        <button className="catalog__buy_button">
                          <BuyButton />
                          Купить
                        </button>
                      </div>

                      {/* Задняя сторона кнопки */}
                      <div className="catalog__flip_card_back">
                        <WB className="catalog__flip_card_back-img" />
                        <Ozon className="catalog__flip_card_back-img" />
                        <VseInstr className="catalog__flip_card_back-img" />
                        <Cancel
                          onClick={() => handleButtonClick("1")}
                          className="catalog__flip_card_back-cancel"
                        />
                      </div>
                    </div>

                    <p
                      onClick={openModal}
                      className="catalog__buy_container_left-text"
                    >
                      Запросить КП для Юрлиц
                    </p>
                  </div>
                  <div className="catalog__buy_size">
                    <div className="catalog__buy_size-container">
                      <Hundred />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">100</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          мл
                        </p>
                      </div>
                    </div>
                    <div className="catalog__buy_size-container">
                      <TwoHundred />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">250</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          мл
                        </p>
                      </div>
                    </div>
                    <div className="catalog__buy_size-container">
                      <EightHundred />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">800</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          мл
                        </p>
                      </div>
                    </div>
                    <div className="catalog__buy_size-container">
                      <Liter />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">1,5</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          л
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info">
                <div className="info__container">
                  <MadeInRussia className="info__container-img" />
                  <div className="info__container-block">
                    <p className="info__container-text">
                      Заменяет все
                      <br /> удобрения
                    </p>
                  </div>
                </div>
                <h2 className="catalog__title catalog__title-mobile">Средство защиты <br/> для хвойных</h2>
                <img src={hvoy} className="catalog__img" />
              </div>
            </div>
            <div className="catalog__separator">
              <Separator className="catalog__separator-item" />
            </div>
            <div className="content">
              <div className="catalog">
                <h2 className="catalog__title">
                  удобрение top green
                  <br /> универсальное
                </h2>
                <p className="catalog__description">
                  для декоративно-лиственных, цветущих
                  <br /> комнатных и садовых растений
                </p>
                <ul className="catalog__list">
                  <li className="catalog__list-item">
                    для корневой и листовой подкормки
                  </li>
                  <li className="catalog__list-item">всесезонное применение</li>
                  <li className="catalog__list-item">
                    исключён риск передозировки
                  </li>
                  <li className="catalog__list-item">
                    высококонцентрированный состав
                  </li>
                </ul>
                <div className="catalog__container">
                  <div className="catalog__container-item">
                    <table className="border-none">
                      <thead>
                        <tr>
                          <th className="catalog__container-item-title">
                            Микроэлементы
                          </th>
                          <th className="catalog__container-item-title">
                            мг/л
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Fe</td>
                          <td>77.76</td>
                        </tr>
                        <tr>
                          <td>Mn</td>
                          <td>60.48</td>
                        </tr>
                        <tr>
                          <td>Zn</td>
                          <td>20.16</td>
                        </tr>
                        <tr>
                          <td>Cu</td>
                          <td>14.4</td>
                        </tr>
                        <tr>
                          <td>Mo</td>
                          <td>5.76</td>
                        </tr>
                        <tr>
                          <td>B</td>
                          <td>28.8</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="catalog__container-item">
                    <h3 className="catalog__container-item-title">
                      Состав(г/л):
                    </h3>
                    <p className="catalog__container-item-text">N - 23.04</p>
                    <p className="catalog__container-item-text">P205 - 23.04</p>
                    <p className="catalog__container-item-text">K20 - 34.08</p>
                    <p className="catalog__container-item-text">MgO - 2.4</p>
                  </div>
                  <div className="catalog__container-item">
                    <h3 className="catalog__container-item-title">
                      Доп. информация:
                    </h3>
                    <p className="catalog__container-item-text">
                      N-NO3- 8.54 г/л
                    </p>
                    <p className="catalog__container-item-text">
                      N-NH4 - 2.98 г/л
                    </p>
                    <p className="catalog__container-item-text">
                      N-NH2 - 11.52 г/л
                    </p>
                  </div>
                </div>
                <div className="catalog__buy_container">
                  <div className="catalog__buy_container_left">
                    <p className="catalog__buy_container_left-shop">
                      <strong>WB / Ozon / Все Инстр.</strong>
                    </p>

                    {/* Блок для кнопки с id="2" */}
                    <div
                      className={`catalog__flip_card ${
                        flippedState["2"] ? "flipped" : ""
                      }`}
                    >
                      {/* Передняя сторона кнопки */}
                      <div
                        onClick={() => handleButtonClick("2")}
                        className="catalog__flip_card_front"
                      >
                        <button className="catalog__buy_button">
                          <BuyButton />
                          Купить
                        </button>
                      </div>

                      {/* Задняя сторона кнопки */}
                      <div className="catalog__flip_card_back">
                        <WB className="catalog__flip_card_back-img" />
                        <Ozon className="catalog__flip_card_back-img" />
                        <VseInstr className="catalog__flip_card_back-img" />
                        <Cancel
                          onClick={() => handleButtonClick("2")}
                          className="catalog__flip_card_back-cancel"
                        />
                      </div>
                    </div>

                    <p
                      onClick={openModal}
                      className="catalog__buy_container_left-text"
                    >
                      Запросить КП для Юрлиц
                    </p>
                  </div>
                  <div className="catalog__buy_size">
                    <div className="catalog__buy_size-container">
                      <Hundred />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">100</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          мл
                        </p>
                      </div>
                    </div>
                    <div className="catalog__buy_size-container">
                      <TwoHundred />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">250</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          мл
                        </p>
                      </div>
                    </div>
                    <div className="catalog__buy_size-container">
                      <EightHundred />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">800</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          мл
                        </p>
                      </div>
                    </div>
                    <div className="catalog__buy_size-container">
                      <Liter />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">1,5</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          л
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info">
                <div className="info__container">
                  <MadeInRussia className="info__container-img" />
                  <div className="info__container-block">
                    <p className="info__container-text">
                      Заменяет все
                      <br /> удобрения
                    </p>
                  </div>
                </div>
                <h2 className="catalog__title catalog__title-mobile">
                  удобрение top green
                  <br /> универсальное
                </h2>
                <img src={universal} className="catalog__img" />
              </div>
            </div>
            <div className="catalog__separator">
              <Separator className="catalog__separator-item" />
            </div>
            <div className="content">
              <div className="catalog">
                <h2 className="catalog__title">
                  Средство защиты <br /> пальмовых растений
                </h2>
                <p className="catalog__description">
                  для пальм, фикусов, юкки и драцен
                  <br /> эффективный рост и усиленный имутитет
                </p>
                <ul className="catalog__list">
                  <li className="catalog__list-item">
                    для корневой и листовой подкормки
                  </li>
                  <li className="catalog__list-item">всесезонное применение</li>
                  <li className="catalog__list-item">
                    исключён риск передозировки
                  </li>
                  <li className="catalog__list-item">защита от болезней</li>
                </ul>
                <div className="catalog__container">
                  <div className="catalog__container-item">
                    <table className="border-none">
                      <thead>
                        <tr>
                          <th className="catalog__container-item-title">
                            Микроэлементы
                          </th>
                          <th className="catalog__container-item-title">
                            мг/л
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Fe</td>
                          <td>77.76</td>
                        </tr>
                        <tr>
                          <td>Mn</td>
                          <td>60.48</td>
                        </tr>
                        <tr>
                          <td>Zn</td>
                          <td>20.16</td>
                        </tr>
                        <tr>
                          <td>Cu</td>
                          <td>14.4</td>
                        </tr>
                        <tr>
                          <td>Mo</td>
                          <td>5.76</td>
                        </tr>
                        <tr>
                          <td>B</td>
                          <td>28.8</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="catalog__container-item">
                    <h3 className="catalog__container-item-title">
                      Состав(г/л):
                    </h3>
                    <p className="catalog__container-item-text">N - 27.36</p>
                    <p className="catalog__container-item-text">P205 - 18.72</p>
                    <p className="catalog__container-item-text">K20 - 18.72</p>
                    <p className="catalog__container-item-text">MgO - 2.52</p>
                  </div>
                  <div className="catalog__container-item">
                    <h3 className="catalog__container-item-title">
                      Доп. информация:
                    </h3>
                    <p className="catalog__container-item-text">
                      N-NO3- 7.70 г/л
                    </p>
                    <p className="catalog__container-item-text">
                      N-NH4 - 11.02 г/л
                    </p>
                    <p className="catalog__container-item-text">
                      N-NH2 - 8.64 г/л
                    </p>
                  </div>
                </div>
                <div className="catalog__buy_container">
                  <div className="catalog__buy_container_left">
                    <p className="catalog__buy_container_left-shop">
                      <strong>WB / Ozon / Все Инстр.</strong>
                    </p>

                    {/* Блок для кнопки с id="3" */}
                    <div
                      className={`catalog__flip_card ${
                        flippedState["3"] ? "flipped" : ""
                      }`}
                    >
                      {/* Передняя сторона кнопки */}
                      {/* Передняя сторона кнопки */}
                      <div
                        onClick={() => handleButtonClick("3")}
                        className="catalog__flip_card_front"
                      >
                        <button className="catalog__buy_button">
                          <BuyButton />
                          Купить
                        </button>
                      </div>

                      {/* Задняя сторона кнопки */}
                      <div className="catalog__flip_card_back">
                        <WB className="catalog__flip_card_back-img" />
                        <Ozon className="catalog__flip_card_back-img" />
                        <VseInstr className="catalog__flip_card_back-img" />
                        <Cancel
                          onClick={() => handleButtonClick("3")}
                          className="catalog__flip_card_back-cancel"
                        />
                      </div>
                    </div>

                    <p
                      onClick={openModal}
                      className="catalog__buy_container_left-text"
                    >
                      Запросить КП для Юрлиц
                    </p>
                  </div>
                  <div className="catalog__buy_size">
                    <div className="catalog__buy_size-container">
                      <Hundred />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">100</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          мл
                        </p>
                      </div>
                    </div>
                    <div className="catalog__buy_size-container">
                      <TwoHundred />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">250</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          мл
                        </p>
                      </div>
                    </div>
                    <div className="catalog__buy_size-container">
                      <EightHundred />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">800</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          мл
                        </p>
                      </div>
                    </div>
                    <div className="catalog__buy_size-container">
                      <Liter />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">1,5</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          л
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info">
                <div className="info__container">
                  <MadeInRussia className="info__container-img" />
                  <div className="info__container-block">
                    <p className="info__container-text">
                      Заменяет все
                      <br /> удобрения
                    </p>
                  </div>
                </div>
                <h2 className="catalog__title catalog__title-mobile">
                  Средство защиты <br /> пальмовых растений
                </h2>
                <img src={palm} className="catalog__img" />
              </div>
            </div>
            <div className="catalog__separator">
              <Separator className="catalog__separator-item" />
            </div>
            <div className="content">
              <div className="catalog">
                <h2 className="catalog__title">
                  Средство защиты <br /> цветущих растений
                </h2>
                <p className="catalog__description">
                  для роз, бегоний и хризантем
                  <br /> эффективный рост и усиленный имутитет
                </p>
                <ul className="catalog__list">
                  <li className="catalog__list-item">
                    для корневой и листовой подкормки
                  </li>
                  <li className="catalog__list-item">всесезонное применение</li>
                  <li className="catalog__list-item">
                    исключён риск передозировки
                  </li>
                  <li className="catalog__list-item">обильное цветение</li>
                </ul>
                <div className="catalog__container">
                  <div className="catalog__container-item">
                    <table className="border-none">
                      <thead>
                        <tr>
                          <th className="catalog__container-item-title">
                            Микроэлементы
                          </th>
                          <th className="catalog__container-item-title">
                            мг/л
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Fe</td>
                          <td>77.76</td>
                        </tr>
                        <tr>
                          <td>Mn</td>
                          <td>60.48</td>
                        </tr>
                        <tr>
                          <td>Zn</td>
                          <td>20.16</td>
                        </tr>
                        <tr>
                          <td>Cu</td>
                          <td>14.4</td>
                        </tr>
                        <tr>
                          <td>Mo</td>
                          <td>5.76</td>
                        </tr>
                        <tr>
                          <td>B</td>
                          <td>28.8</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="catalog__container-item">
                    <h3 className="catalog__container-item-title">
                      Состав(г/л):
                    </h3>
                    <p className="catalog__container-item-text">N - 24.48</p>
                    <p className="catalog__container-item-text">P205 - 7.92</p>
                    <p className="catalog__container-item-text">K20 - 36</p>
                    <p className="catalog__container-item-text">MgO - 2.3</p>
                  </div>
                  <div className="catalog__container-item">
                    <h3 className="catalog__container-item-title">
                      Доп. информация:
                    </h3>
                    <p className="catalog__container-item-text">
                      Азот N(общий)- 24.48 %
                    </p>
                    <p className="catalog__container-item-text">
                      в т.ч. N-NO3 - 16.70 %
                    </p>
                    <p className="catalog__container-item-text">
                      N-NH4 - 7.78 %
                    </p>
                  </div>
                </div>
                <div className="catalog__buy_container">
                  <div className="catalog__buy_container_left">
                    <p className="catalog__buy_container_left-shop">
                      <strong>WB / Ozon / Все Инстр.</strong>
                    </p>

                    {/* Блок для кнопки с id="4" */}
                    <div
                      className={`catalog__flip_card ${
                        flippedState["4"] ? "flipped" : ""
                      }`}
                    >
                      {/* Передняя сторона кнопки */}
                      {/* Передняя сторона кнопки */}
                      <div
                        onClick={() => handleButtonClick("4")}
                        className="catalog__flip_card_front"
                      >
                        <button className="catalog__buy_button">
                          <BuyButton />
                          Купить
                        </button>
                      </div>

                      {/* Задняя сторона кнопки */}
                      <div className="catalog__flip_card_back">
                        <WB className="catalog__flip_card_back-img" />
                        <Ozon className="catalog__flip_card_back-img" />
                        <VseInstr className="catalog__flip_card_back-img" />
                        <Cancel
                          onClick={() => handleButtonClick("4")}
                          className="catalog__flip_card_back-cancel"
                        />
                      </div>
                    </div>

                    <p
                      onClick={openModal}
                      className="catalog__buy_container_left-text"
                    >
                      Запросить КП для Юрлиц
                    </p>
                  </div>
                  <div className="catalog__buy_size">
                    <div className="catalog__buy_size-container">
                      <Hundred />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">100</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          мл
                        </p>
                      </div>
                    </div>
                    <div className="catalog__buy_size-container">
                      <TwoHundred />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">250</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          мл
                        </p>
                      </div>
                    </div>
                    <div className="catalog__buy_size-container">
                      <EightHundred />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">800</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          мл
                        </p>
                      </div>
                    </div>
                    <div className="catalog__buy_size-container">
                      <Liter />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">1,5</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          л
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info">
                <div className="info__container">
                  <MadeInRussia className="info__container-img" />
                  <div className="info__container-block">
                    <p className="info__container-text">
                      Заменяет все
                      <br /> удобрения
                    </p>
                  </div>
                </div>
                <h2 className="catalog__title catalog__title-mobile">
                  Средство защиты <br /> цветущих растений
                </h2>
                <img src={rose} className="catalog__img" />
              </div>
            </div>
            <div className="catalog__separator">
              <Separator className="catalog__separator-item" />
            </div>
            <div className="content">
              <div className="catalog">
                <h2 className="catalog__title">
                  Средство защиты <br /> орхидных растений
                </h2>
                <p className="catalog__description">
                  для орхидей, фаленопсиса, мильтонии,
                  <br /> каттлея и пр. орхидных
                </p>
                <ul className="catalog__list">
                  <li className="catalog__list-item">
                    для корневой и листовой подкормки
                  </li>
                  <li className="catalog__list-item">всесезонное применение</li>
                  <li className="catalog__list-item">
                    исключён риск передозировки
                  </li>
                  <li className="catalog__list-item">обильное цветение</li>
                </ul>
                <div className="catalog__container">
                  <div className="catalog__container-item">
                    <table className="border-none">
                      <thead>
                        <tr>
                          <th className="catalog__container-item-title">
                            Микроэлементы
                          </th>
                          <th className="catalog__container-item-title">
                            мг/л
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Fe</td>
                          <td>77.76</td>
                        </tr>
                        <tr>
                          <td>Mn</td>
                          <td>60.48</td>
                        </tr>
                        <tr>
                          <td>Zn</td>
                          <td>20.16</td>
                        </tr>
                        <tr>
                          <td>Cu</td>
                          <td>14.4</td>
                        </tr>
                        <tr>
                          <td>Mo</td>
                          <td>5.76</td>
                        </tr>
                        <tr>
                          <td>B</td>
                          <td>28.8</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="catalog__container-item">
                    <h3 className="catalog__container-item-title">
                      Состав(г/л):
                    </h3>
                    <p className="catalog__container-item-text">N - 18.72</p>
                    <p className="catalog__container-item-text">P205 - 7.20</p>
                    <p className="catalog__container-item-text">K20 - 36</p>
                    <p className="catalog__container-item-text">MgO - 2.88</p>
                  </div>
                  <div className="catalog__container-item">
                    <h3 className="catalog__container-item-title">
                      Доп. информация:
                    </h3>
                    <p className="catalog__container-item-text">
                      Азот N(общий)- 18.72 %
                    </p>
                    <p className="catalog__container-item-text">
                      в т.ч. N-NO3 - 10.08 %
                    </p>
                    <p className="catalog__container-item-text">
                      N-NH4 - 8.64 %
                    </p>
                  </div>
                </div>
                <div className="catalog__buy_container">
                  <div className="catalog__buy_container_left">
                    <p className="catalog__buy_container_left-shop">
                      <strong>WB / Ozon / Все Инстр.</strong>
                    </p>

                    {/* Блок для кнопки с id="5" */}
                    <div
                      className={`catalog__flip_card ${
                        flippedState["5"] ? "flipped" : ""
                      }`}
                    >
                      {/* Передняя сторона кнопки */}
                      {/* Передняя сторона кнопки */}
                      <div
                        onClick={() => handleButtonClick("5")}
                        className="catalog__flip_card_front"
                      >
                        <button className="catalog__buy_button">
                          <BuyButton />
                          Купить
                        </button>
                      </div>

                      {/* Задняя сторона кнопки */}
                      <div className="catalog__flip_card_back">
                        <WB className="catalog__flip_card_back-img" />
                        <Ozon className="catalog__flip_card_back-img" />
                        <VseInstr className="catalog__flip_card_back-img" />
                        <Cancel
                          onClick={() => handleButtonClick("5")}
                          className="catalog__flip_card_back-cancel"
                        />
                      </div>
                    </div>

                    <p
                      onClick={openModal}
                      className="catalog__buy_container_left-text"
                    >
                      Запросить КП для Юрлиц
                    </p>
                  </div>
                  <div className="catalog__buy_size">
                    <div className="catalog__buy_size-container">
                      <Hundred />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">100</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          мл
                        </p>
                      </div>
                    </div>
                    <div className="catalog__buy_size-container">
                      <TwoHundred />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">250</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          мл
                        </p>
                      </div>
                    </div>
                    <div className="catalog__buy_size-container">
                      <EightHundred />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">800</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          мл
                        </p>
                      </div>
                    </div>
                    <div className="catalog__buy_size-container">
                      <Liter />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">1,5</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          л
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info">
                <div className="info__container">
                  <MadeInRussia className="info__container-img" />
                  <div className="info__container-block">
                    <p className="info__container-text">
                      Заменяет все
                      <br /> удобрения
                    </p>
                  </div>
                </div>
                <h2 className="catalog__title catalog__title-mobile">
                  Средство защиты <br /> орхидных растений
                </h2>
                <img src={orc} className="catalog__img" />
              </div>
            </div>
          </div>
        );
      case "legalEntities":
        return (
          <div>
            {" "}
            <div className="catalog__main-description">
              <p className="catalog__main-description-text-top">
                TOP GREEN — Российский бренд, производящий высококачественные
                удобрения для различных видов растений (таких как хвойные,
                пальмы, фикусы, драцены), а также удобрения для универсального
                применения.
              </p>
              <p className="catalog__main-description-text-bottom">
                Наши удобрения способствуют росту и развитию растений, защищают
                от вредителей, обеспечивают быстрые сроки восстановления после
                стресса. Продукция TOP GREEN исключает риск передозировки, а
                также является полностью безопасной для использования.
              </p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="content-switcher">
      <div className="content-switcher__buttons">
        <button
          className={`content-switcher__buttons-item ${
            activeContent === "catalog" ? "active" : ""
          }`}
          onClick={() => setActiveContent("catalog")}
        >
          Каталог
        </button>
        <button
          className={`content-switcher__buttons-item ${
            activeContent === "legalEntities" ? "active" : ""
          }`}
          onClick={() => setActiveContent("legalEntities")}
        >
          Для юрлиц
        </button>
        <button className="content-switcher__buttons-item" onClick={openModal}>
          Заявка
        </button>
        {isModalOpen && <Modal closeModal={closeModal} />}
      </div>
      <div className="content">{renderContent()}</div>
    </div>
  );
}

export default ContentSwitcher;
