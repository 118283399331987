import "../styles/Header.css";
import { ReactComponent as DownloadButton } from "../img/download.svg";
import { ReactComponent as Logo } from "../img/logoBlack.svg";
import { useEffect, useState } from "react";
import background from "../img/header_background.jpg";
import universal from "../img/header_universal.png";
import hvoy from "../img/header_hvoy.png";
import orc from "../img/header_orc.png";
import flower from "../img/flower.png";
import Modal from "./Modal";

function Header() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Открытие модального окна
  const openModal = () => setIsModalOpen(true);

  // Закрытие модального окна
  const closeModal = () => setIsModalOpen(false);

  // Закрытие модального окна при нажатии Escape
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        closeModal();
      }
    };

    if (isModalOpen) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isModalOpen]);

  return (
    <div className="header__container">
      <div className="header">
        <div className="header__left-menu">
          <button onClick={openModal} className="header__left-menu-button">
            Оставить заявку
          </button>
          {isModalOpen && <Modal closeModal={closeModal} />}
          <div className="header__left-menu-container">
            <DownloadButton />
            <a
              href="../img/presentation.pdf"
              download="presentation.pdf"
              className="header__left-menu-download"
            >
              Скачать
              <br /> презентацию
            </a>
          </div>
        </div>
        <div className="header__logo">
          <Logo className="header__logo-img" />
          <h1 className="header__title">
            Профессиональные удобрения Top Green
            <br />
            для универсального применения
          </h1>
          <div className="background-container background-container-mobile">
        <img
          src={background}
          alt="Background Image"
          className="header__background"
        />
        <img
          src={orc}
          alt="Background Image"
          className="header__background-image orc"
        />
        <img
          src={hvoy}
          alt="Background Image"
          className="header__background-image hvoy"
        />
        <img
          src={universal}
          alt="Background Image"
          className="header__background-image universal"
        />
        <img
          src={flower}
          alt="Background Image"
          className="flower"
        />
      </div>
        </div>
        <div className="header__right-menu">
          <a href="tel:+74951067269" className="header__link-number">
            +7 (495) 106-72-69
          </a>
          <p className="header__right-menu-text">
            Оставить заявку или получить
            <br /> консультацию специалиста
          </p>
          <a className="header__mail" href="mailto:info@efektiv.ru">
            info@efektiv.ru
          </a>
        </div>
      </div>
      <div className="background-container">
        <img
          src={background}
          alt="Background Image"
          className="header__background"
        />
        <img
          src={orc}
          alt="Background Image"
          className="header__background-image orc"
        />
        <img
          src={hvoy}
          alt="Background Image"
          className="header__background-image hvoy"
        />
        <img
          src={universal}
          alt="Background Image"
          className="header__background-image universal"
        />
        <img
          src={flower}
          alt="Background Image"
          className="flower"
        />
      </div>
    </div>
  );
}

export default Header;
