import React, { useState } from "react";
import "../styles/Footer.css";
import { ReactComponent as FooterDownloadButton } from "../img/footerDownload.svg";
import { ReactComponent as Logo } from "../img/logoWhite.svg";
import leafLeft from "../img/leafLeft.png";
import leafRight from "../img/leafRight.png";
import Modal from "./Modal";

function Footer() {
  const [isModalOpen] = useState(true); // Оставляем модальное окно открытым

  const scrollToElement = (selector) => (event) => {
    event.preventDefault();
    document.querySelector(selector).scrollIntoView({ behavior: 'smooth' });
};


  return (
    <footer className="footer__container">
      <div className="footer__top">
        <img src={leafLeft} className="leaf-left" />
        <img src={leafRight} className="leaf-right" />
        <Logo className="footer__top-logo" />
        <p className="footer__top-text">
          Мы гарантируем индивидуальный подход к каждому партнеру и предлагаем
          оптимальные условия сотрудничества. <br /> Оставьте заявку, и наш
          менеджер свяжется с вами в ближайшее время.
        </p>
        <button
          className="footer__top-button"
          onClick={scrollToElement("#footer__bottom")}
        >
          Оставить заявку
        </button>
      </div>

      <div className="footer__bottom" id="footer__bottom">
        <p className="footer__bottom-modal">Форма обратной связи:</p>

        {/* Постоянное модальное окно в футере */}
        {isModalOpen && <Modal closeModal={() => {}} isFooterModal={true} />}

        <div className="footer__main">
          <Logo className="footer-logo" />
          <p className="footer__title">
            Профессиональные удобрения Top Green для универсального применения
          </p>
          <div className="contact-info">
            <p>
              <a className="footer__number" href="tel:+74951067269">
                +7 (495) 106-72-69
              </a>
            </p>
            <p>
              <a className="footer__mail" href="mailto:info@topgreen.ru">
                info@topgreen.ru
              </a>
            </p>
          </div>
          <div className="footer__download">
            <FooterDownloadButton />
            <a
              href="../img/presentation.pdf"
              download="presentation.pdf"
              className="footer__download-button"
            >
              Скачать
              <br /> презентацию
            </a>
          </div>
        </div>
        <div className="footer__last">
          <p className="footer__last-text">
            2024 © Efektiv - Российский производитель профессиональной бытовой
            химии. Все права защищены. Вся представленная информация носит
            <br />
            информационный характер и ни при каких условиях не является
            публичной офертой, определяемой положениями Статьи 437 (2)
            Гражданского кодекса РФ
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
